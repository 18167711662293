/* styles.css */
.fade-enter {
    opacity: 0;
    position: absolute; /* Aseguramos que el contenido entrante esté encima */
    width: 100%;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in-out; /* Suavidad de la animación */
  }
  
  .fade-exit {
    opacity: 1;
    position: absolute; /* Asegura que el contenido saliente permanezca durante la transición */
    width: 100%;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in-out; /* Transición para desaparecer */
  }
  