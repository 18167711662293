/* Home.css */

.home-container {  
  position: relative;  
  height: 100vh;   
  overflow: hidden;  
  background-image: url('../../images/noticias/Noticias_bg.png');
  background-size: cover;
  background-position: center;
}

.header, .hero {  
  position: relative;  
  z-index: 1;  
}