/* Media queries para ajustar el ancho, el tamaño del texto y esconder elementos según la altura */
@media (max-height: 800px) {
    .transformacion-container {
      width: 100% !important;
    }
  
    .transformacion-title {
      font-size: 1.75rem; /* Reducir el tamaño del título */
    }
  
    .transformacion-paragraph {
      font-size: 0.9rem; /* Reducir el tamaño del párrafo */
    }
  }
  
  @media (max-height: 720px) {
    .transformacion-paragraph {
      display: none; /* Ocultar el párrafo cuando la altura es menor a 720px */
    }
  }
  
  @media (max-height: 600px) {
    .transformacion-container {
      width: 120% !important;
    }
  
    .transformacion-title {
      font-size: 1.5rem; /* Reducir aún más el tamaño del título */
    }
  
    .transformacion-paragraph {
      font-size: 0.8rem; /* Reducir aún más el tamaño del párrafo si no está oculto */
    }
  }
  
  @media (max-height: 590px) {
    .transformacion-title {
      display: none; /* Ocultar el título cuando la altura es menor a 590px */
    }
  }
  