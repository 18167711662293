@tailwind base;
@tailwind components;
@tailwind utilities;
/* index.css or App.css */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
/* index.css or App.css */
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Montserrat','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* index.css or App.css */
body {
  font-family:  sans-serif;
}


body {
  @apply transition-colors duration-300;
}

/* Estilos generales para el modo claro */
body {
  background-color: white;
  color: black;
}

/* Estilos para el modo oscuro */
body.dark {
  background-color: #0c0c0c; /* gris oscuro típico */
  color: white;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.font-bebas {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 18px;
  /* text-align: left; */
}
/* styles.css */

